<template>
  <div>
    <!-- <el-row> -->
    <el-col>
      <!-- <el-collapse v-model="activeTab">
          <el-collapse-item name="1"> -->
      <template slot="title">
        Filters (to get only required entities data) ==>

        <el-tag v-if="field && field.filters && field.filters.length">
          {{ field.filters.length }}</el-tag
        >
      </template>
      <div>
        <el-button @click="addFilter" type="text" class="mr-3">
          Add +
        </el-button>
        <el-radio-group v-model="query_type" @change="updateQueryType">
          <el-radio label="AND"></el-radio>
          <el-radio label="OR"></el-radio>
        </el-radio-group>
        <div
          class="float-right"
          v-if="
            field &&
            field.data_table_key &&
            field.data_table_field_index > -1 &&
            checkOtherAutofills
          "
        >
          Auto fill
          <el-radio-group
            v-model="field.data_table_auto_fill"
            style="margin-left: 40px"
          >
            <el-radio label="all_data">All Data</el-radio>
            <el-radio label="fill_preferred_data" v-if="checkPreferredFilter"
              >Fill data with preferred options</el-radio
            >
          </el-radio-group>
          <el-link
            v-if="field.data_table_auto_fill"
            class="ml-1"
            type="primary"
            :underline="false"
            @click="field.data_table_auto_fill = ''"
          >
            Clear
          </el-link>
        </div>
      </div>
      <!-- <el-scrollbar wrap-style="max-height: 100px;"> -->
      <table v-loading="loading">
        <tr v-for="(filter, index) of field.filters" :key="index">
          <td v-if="!filter.hide_filter">
            <tr>
              <td>
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  :content="
                    filter && filter.field == 'UNIQUE'
                      ? `Eliminate already selected ${field.label}.`
                      : 'Select field'
                  "
                >
                  <el-select
                    v-model="filter.field"
                    placeholder="Field"
                    @change="
                      (changedValue) =>
                        checkCurrentFieldData(changedValue, filter, index)
                    "
                    size="mini"
                    :id="`filters_${index}`"
                    :ref="`filters_${index}`"
                    slot="reference"
                  >
                    <el-option
                      v-for="(field, index) of selectedEntityFields"
                      :key="index"
                      :value="field.key"
                      :label="`${field.template_name} - ${field.label} - ${field.data_type} `"
                    >
                      {{ field.template_name }} - {{ field.label }}
                    </el-option>
                    <el-option
                      label="Eliminate"
                      value="UNIQUE"
                      v-if="field.input_type !== 'ENTITY_TABLE'"
                    >
                    </el-option>
                    <el-option
                      :disabled="
                        checkFilterExistedOrNot({ key: 'RESOURCE_MANAGEMENT' })
                      "
                      label="Resource management"
                      value="RESOURCE_MANAGEMENT"
                      v-if="
                        field && field.allow_multiple && field.auto_complete
                      "
                    >
                    </el-option>
                  </el-select>
                </el-popover>
              </td>
              <template v-if="filter.field === 'UNIQUE'">
                <td>
                  <el-popover placement="top-start" trigger="hover">
                    <div style="width: 200px">
                      <div style="word-break: keep-all !important">
                        <b>Group by:</b> You can group current entity data by a
                        field. So one {{ field.label }} can be selected to this
                        grouped data based on limit value.
                      </div>
                      <div>
                        <b>One to one:</b> One {{ field.label }} can be selected
                        to one current entity data based on limit value.
                      </div>
                    </div>
                    <el-select
                      v-model="filter.unique_field"
                      size="mini"
                      placeholder="Unique field"
                      slot="reference"
                      clearable
                      @change="(value) => setFilterInputType(value, filter)"
                    >
                      <el-option-group label="Group by fields">
                        <el-option
                          v-for="(option, index) in getTemplateGroupByFields"
                          :value="option.template_id + '#' + option.key"
                          :label="option.label"
                          :key="index"
                        ></el-option>
                      </el-option-group>
                      <el-option-group label="One to one">
                        <el-option label="One to one" value="ALL_DATA">
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </el-popover>
                </td>
                <td>
                  <el-select
                    v-model="filter.data_source"
                    size="mini"
                    placeholder="Resource count"
                    clearable
                  >
                    <el-option label="VALUE" value="VALUE"> </el-option>
                    <el-option label="TEMPLATE" value="TEMPLATE"> </el-option>
                  </el-select>
                </td>
                <td>
                  <el-input-number
                    v-model="filter.value"
                    v-if="filter.data_source == 'VALUE'"
                    :controls="false"
                    size="mini"
                    placeholder="Resource value"
                    :min="1"
                  ></el-input-number>
                  <el-select
                    v-if="filter.data_source == 'TEMPLATE'"
                    size="mini"
                    placeholder="Resource field"
                    v-model="filter.value_field"
                    clearable
                  >
                    <el-option
                      v-for="(option, index) of getMatchedTypeEntityFields(
                        filter,
                        'NUMBER'
                      )"
                      :value="option.template_id + '#' + option.key"
                      :label="option.label"
                      :key="index"
                    ></el-option>
                  </el-select>
                </td>
                <!-- <td>
                  <el-select
                    size="mini"
                    placeholder="Consume value"
                    v-model="filter.limit_field"
                    clearable
                  >
                    <el-option
                      v-for="(option, index) of getMatchedTypeFields(
                        filter,
                        'NUMBER'
                      )"
                      :value="option.template_id + '#' + option.key"
                      :label="option.label"
                      :key="index"
                    ></el-option>
                  </el-select>
                </td> -->
              </template>
              <template v-else-if="filter.field == 'RESOURCE_MANAGEMENT'">
                <td>
                  <el-popover placement="top-start" trigger="hover">
                    <div style="width: 230px">
                      <div style="word-break: keep-all !important">
                        <b>Note:</b>This filter won't apply, you can valuate the
                        {{ field.label }} value and update the
                        {{ field.label }} state
                      </div>
                      <div style="word-break: keep-all !important">
                        <b>Resource count fields:</b> You can take
                        {{ field.label }}
                        count from current entity fields
                      </div>
                      <div>
                        <b>Total data:</b> One data is considered as one
                        {{ field.label }}
                      </div>
                    </div>
                    <el-select
                      v-model="filter.resource_count"
                      size="mini"
                      placeholder="Resource field"
                      slot="reference"
                      clearable
                    >
                      <el-option-group label="Resource count fields">
                        <el-option
                          v-for="(
                            option, index
                          ) in getSelectedEntityCountFields(['NUMBER'])"
                          :value="option.template_id + '#' + option.key"
                          :label="option.label"
                          :key="index + '_field_r'"
                        ></el-option>
                      </el-option-group>
                      <el-option-group label="Total data">
                        <el-option label="1 data = 1 resource" value="ALL_DATA">
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </el-popover>
                </td>
                <td>
                  <el-select
                    v-model="filter.resource_source"
                    size="mini"
                    placeholder="Resource value"
                    clearable
                  >
                    <el-option label="VALUE" value="VALUE"> </el-option>
                    <el-option label="TEMPLATE" value="TEMPLATE"> </el-option>
                  </el-select>
                </td>
                <td>
                  <el-select
                    v-if="filter.resource_source == 'TEMPLATE'"
                    v-model="filter.resource_value_field"
                    size="mini"
                    placeholder="Resource Value"
                    slot="reference"
                    clearable
                  >
                    <el-option-group label="Resource value fields">
                      <el-option
                        v-for="(option, index) in getSelectedEntityCountFields([
                          'CURRENCY',
                        ])"
                        :value="option.template_id + '#' + option.key"
                        :label="option.label"
                        :key="index + '_field_r'"
                      ></el-option>
                    </el-option-group>
                    <!-- <el-option-group label="Total data">
                      <el-option label="1 data = 1 resource" value="ALL_DATA">
                      </el-option>
                    </el-option-group> -->
                  </el-select>
                  <el-input-number
                    v-model="filter.resource_value"
                    v-if="filter.resource_source == 'VALUE'"
                    :controls="false"
                    size="mini"
                    placeholder="Consumed value"
                    :min="1"
                  ></el-input-number>
                </td>
                <td>
                  <el-select
                    v-model="filter.data_source"
                    size="mini"
                    placeholder="Consumed value"
                    clearable
                  >
                    <el-option label="VALUE" value="VALUE"> </el-option>
                    <el-option label="TEMPLATE" value="TEMPLATE"> </el-option>
                  </el-select>
                </td>
                <td>
                  <el-input-number
                    v-model="filter.value"
                    v-if="filter.data_source == 'VALUE'"
                    :controls="false"
                    size="mini"
                    placeholder="Consumed value"
                    :min="1"
                  ></el-input-number>
                  <el-select
                    v-if="filter.data_source == 'TEMPLATE'"
                    size="mini"
                    placeholder="Consume field"
                    v-model="filter.value_field"
                    clearable
                  >
                    <el-option
                      v-for="(option, index) of getCurrentTemplateCountFields([
                        'NUMBER',
                      ])"
                      :value="option.template_id + '#' + option.key"
                      :label="option.label"
                      :key="index + '_c_field'"
                    ></el-option>
                  </el-select>
                </td>
                <td>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Update resource"
                    placement="top-start"
                  >
                    <el-button
                      type="text"
                      size="mini"
                      @click="openUpdateResourceModal(filter, index)"
                    >
                      Update resource
                    </el-button>
                  </el-tooltip>
                </td>
              </template>
              <template v-else>
                <td>
                  <el-select
                    v-model="filter.operator"
                    size="mini"
                    :disabled="!filter.field"
                  >
                    <el-option
                      v-for="(operator, index) of getCurrentFieldOperators(
                        filter
                      )"
                      :key="index"
                      :label="operator.title"
                      :value="operator.value"
                    >
                    </el-option>
                    <el-option
                      v-if="
                        filter.input_type == 'SELECT' &&
                        filter.data_type == 'ARRAY'
                      "
                      value="=text-includes"
                      label="Text Includes"
                    ></el-option>
                  </el-select>
                </td>
                <td
                  v-if="
                    filter.operator != 'exists' &&
                    filter.operator != 'notexists' &&
                    filter.operator != 'real_time' &&
                    filter.operator != 'weekdays' &&
                    filter.field != 'self#related_to/parent' &&
                    filter.field != 'self#related_to/self'
                  "
                >
                  <el-select
                    v-model="filter.data_source"
                    @change="(value) => checkSourceDataChange(value, filter)"
                    size="mini"
                    :disabled="!filter.operator"
                  >
                    <el-option
                      v-for="(source, index) of allowedDataSources(filter)"
                      :key="index"
                      :label="source"
                      :value="source"
                    >
                    </el-option>
                    <el-option
                      v-if="filter.data_type == 'WEEKDAYS'"
                      value="weekdays"
                      label="Weekdays"
                    >
                    </el-option>
                    <el-option
                      v-if="filter.data_type == 'WEEKDAYS'"
                      value="from_date"
                      label="From date"
                    >
                    </el-option>
                    <el-option
                      v-if="checkIsSelect(filter)"
                      value="field_option"
                      label="From field"
                    >
                    </el-option>
                    <el-option
                      v-if="checkSelfTemplateFields(filter)"
                      value="self_field"
                      label="Template"
                    >
                    </el-option>
                    <el-option value="TEMPLATE" label="Template"> </el-option>
                    <el-option
                      v-if="checkUserEntites(filter)"
                      value="user_entity"
                      label="User"
                    >
                    </el-option>
                    <el-option
                      v-if="filter.data_type == 'STRING'"
                      value="login_user"
                      label="FROM LOGIN USER"
                    ></el-option>
                    <el-option
                      v-if="
                        filter.input_type == 'SELECT' &&
                        filter.data_type == 'ARRAY' &&
                        filter.operator == '=text-includes'
                      "
                      value="VALUE"
                      label="Value"
                    ></el-option>
                  </el-select>
                </td>

                <td
                  v-if="
                    filter.operator != 'exists' &&
                    filter.operator != 'notexists' &&
                    filter.field != 'self#related_to/parent' &&
                    filter.field != 'self#related_to/self'
                  "
                >
                  <el-date-picker
                    v-if="
                      filter.data_type == 'DATE' &&
                      filter.operator == 'between' &&
                      filter.data_source == 'VALUE'
                    "
                    v-model="filter.value"
                    size="mini"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                  ></el-date-picker>
                  <el-date-picker
                    v-if="
                      filter.data_type == 'DATE' &&
                      filter.operator == 'notbetween' &&
                      filter.data_source == 'VALUE'
                    "
                    v-model="filter.value"
                    size="mini"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                  ></el-date-picker>

                  <el-select
                    v-model="filter.value_field"
                    v-if="
                      filter.data_source == 'TEMPLATE' &&
                      filter.data_type == 'DATE' &&
                      filter.data_source == 'TEMPLATE' &&
                      ['between', 'notbetween'].includes(filter.operator)
                    "
                    size="mini"
                  >
                    <el-option
                      v-for="(option, index) of getMatchedTypeFields(
                        filter,
                        'DATE_RANGE'
                      )"
                      :value="option.key"
                      :label="option.label"
                      :key="index"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-model="filter.value_field"
                    v-if="
                      filter.data_source == 'from_date' &&
                      filter.data_type == 'WEEKDAYS'
                    "
                    size="mini"
                  >
                    <el-option
                      v-for="(option, index) of getMatchedTypeFields(
                        filter,
                        'DATE'
                      )"
                      :value="option.key"
                      :label="option.label"
                      :key="index"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-model="filter.value"
                    v-if="filter.data_type == 'BOOLEAN'"
                    size="mini"
                  >
                    <el-option :value="true" label="Checked"></el-option>
                    <el-option :value="false" label="Unchecked"></el-option>
                  </el-select>
                  <el-select
                    v-model="filter.value"
                    v-if="filter.data_type == 'YES_OR_NO'"
                    size="mini"
                  >
                    <el-option value="YES" label="Yes"></el-option>
                    <el-option value="NO" label="No"></el-option>
                  </el-select>
                  <el-select
                    v-model="filter.value"
                    v-if="
                      filter.data_type == 'DATE' &&
                      filter.operator == 'weekdays'
                    "
                    size="mini"
                    multiple
                    collapse-tags
                  >
                    <el-option
                      v-for="(option, index) of weekdays_options"
                      :value="option.value"
                      :label="option.name"
                      :key="index"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-model="filter.value"
                    v-if="
                      filter.data_source == 'weekdays' &&
                      filter.data_type == 'WEEKDAYS'
                    "
                    size="mini"
                    multiple
                    collapse-tags
                  >
                    <el-option
                      v-for="(option, index) of weekdays_options"
                      :value="option.key"
                      :label="option.name"
                      :key="index"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-model="filter.value_field"
                    size="mini"
                    v-if="filter.data_source == 'login_user'"
                    @change="
                      (changedValue) =>
                        checkCurrentFieldValue(changedValue, filter)
                    "
                  >
                    <el-option
                      v-for="(op, index) in loginUserFields"
                      :key="index + op.value"
                      :label="op.label"
                      :value="op.value"
                      >{{ op.label }}</el-option
                    >
                  </el-select>
                  <el-input
                    v-model="filter.value"
                    v-if="
                      filter.data_source == 'VALUE' &&
                      (filter.data_type == 'STRING' ||
                        (filter.input_type == 'SELECT' &&
                          filter.data_type == 'ARRAY' &&
                          filter.operator == '=text-includes'))
                    "
                    size="mini"
                  ></el-input>

                  <el-input-number
                    v-model="filter.value"
                    v-if="
                      filter.data_source == 'VALUE' &&
                      filter.data_type == 'NUMBER'
                    "
                    :controls="false"
                    size="mini"
                  ></el-input-number>
                  <el-date-picker
                    v-model="filter.value"
                    size="mini"
                    v-if="
                      filter.data_source == 'VALUE' &&
                      filter.operator != 'between' &&
                      filter.operator !== 'notbetween' &&
                      filter.data_type == 'DATE'
                    "
                    :format="
                      getDefaultDateFormat &&
                      getDefaultDateFormat.includes('Do') == false
                        ? getDefaultDateFormat
                            .replace('YYYY', 'yyyy')
                            .replace('DD', 'dd')
                        : 'MM-dd-yyyy'
                    "
                  >
                  </el-date-picker>
                  <el-select
                    v-model="filter.global_variable_id"
                    v-if="filter.data_source == 'GLOBAL_VARIABLE'"
                    no-data-text="No Global Varibles with Field"
                    size="mini"
                  >
                    <el-option
                      v-for="(gbVab, index) of selectedGlobalVariablesList(
                        filter
                      )"
                      :key="index"
                      :label="`${gbVab.label} -> ${
                        gbVab.value ? gbVab.value : ''
                      }`"
                      :value="gbVab._id"
                    >
                    </el-option>
                  </el-select>

                  <el-select
                    v-if="filter.data_source == 'NEW_LIST'"
                    placeholder="Enter values"
                    v-model="filter.value"
                    :clearable="true"
                    :allow-create="true"
                    :default-first-option="true"
                    :filterable="true"
                    :multiple="true"
                    :no-data-text="''"
                    @change="(data) => parseOptionsAsNumbers(data, filter)"
                    size="mini"
                  />
                  <!-- <el-time-select
                    v-model="filter.value"
                    size="mini"
                    placeholder="Enter time"
                    v-if="
                      filter.data_source == 'VALUE' &&
                      filter.data_type == 'TIME' &&
                      filter.operator != 'between'
                    "
                    :picker-options="{
                      start: '00:00:00',
                      step: '00:30:00',
                      end: '23:59:00',
                      format: 'hh:mm:ss',
                    }"
                    :value-format="'hh:mm:ss'"
                  ></el-time-select> -->
                  <el-time-picker
                    v-model="filter.value"
                    size="mini"
                    v-if="
                      filter.data_source == 'VALUE' &&
                      (filter.data_type == 'TIME' ||
                        filter.data_type == 'TIME_RANGE') &&
                      filter.operator != 'between'
                    "
                    :picker-options="{
                      format: 'HH:mm:ss',
                    }"
                    :value-format="'HH:mm:ss'"
                  ></el-time-picker>
                  <el-select
                    v-model="filter.value"
                    v-if="filter.data_source == 'ENTITY_DATA_LIST'"
                    multiple
                    collapse-tags
                    size="mini"
                  >
                    <el-option
                      v-for="(temp, i) in entilistAsOptions(filter)"
                      :key="temp._id + '_' + index + '_' + i"
                      :value="temp._id"
                      :label="getLabel(temp, i)"
                      >{{ getLabel(temp, i) }}
                    </el-option>
                  </el-select>

                  <!-- <el-input v-if="filter.operator != 'between' && filter.operator !== 'notbetween' && (filter.data_source == '' ||
                filter.data_source == undefined)
                " :disabled="true" v-model="filter.value" size="mini"></el-input> -->
                  <el-select
                    v-if="
                      filter.data_source == 'TEMPLATE' &&
                      !(
                        filter.data_source == 'TEMPLATE' &&
                        filter.data_type == 'DATE' &&
                        filter.data_source == 'TEMPLATE' &&
                        ['between', 'notbetween'].includes(filter.operator)
                      )
                    "
                    v-model="filter.value_field"
                    size="mini"
                    placeholder="Template fields"
                  >
                    <el-option
                      v-for="(enityData, index) of getSelfTemplateFieldsByType(
                        filter
                      )"
                      :key="index"
                      :label="enityData.label"
                      :value="enityData.key"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-model="filter.value_field"
                    v-if="filter.data_source == 'self_field'"
                    collapse-tags
                    size="mini"
                    @change="
                      (value) => onSelfFieldSelectedForFilter(filter, value)
                    "
                    placeholder="Template fields"
                  >
                    <el-option
                      v-for="(enityData, index) of getSelfTemplateFields(
                        filter
                      )"
                      :key="index"
                      :label="enityData.label"
                      :value="enityData.key"
                      :disabled="fieldDisabled(enityData)"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-model="filter.value"
                    v-if="filter.data_source == 'field_option'"
                    collapse-tags
                    size="mini"
                    :multiple="
                      filter.operator == 'in' || filter.operator == 'nin'
                        ? true
                        : false
                    "
                    placeholder="Field options"
                  >
                    <el-option
                      v-for="(enityData, index) of getCurrentFieldOptions(
                        filter
                      )"
                      :key="index"
                      :label="enityData"
                      :value="enityData"
                    >
                    </el-option>
                  </el-select>
                </td>
                <td
                  v-if="
                    filter &&
                    filter.data_type == 'DATE_TIME' &&
                    filter.type == 'DATE_TIME_RANGE' &&
                    scope.row.entityData[filter.template_id] &&
                    scope.row.entityData[filter.template_id][
                      `${filter.id}_from`
                    ]
                  "
                >
                  {{
                    scope.row.entityData[filter.template_id][
                      `${filter.id}_from`
                    ] | moment("MM-DD-YYYY hh:mm:ss A")
                  }}
                  (From ~ To)
                  {{
                    scope.row.entityData[filter.template_id][`${filter.id}_to`]
                      | moment("MM-DD-YYYY hh:mm:ss A")
                  }}
                </td>
                <td>
                  <el-select
                    v-if="
                      filter.data_source == 'REAL_TIME_PICKER' &&
                      filter.data_type == 'DATE_TIME' &&
                      filter.operator != 'real_time' &&
                      filter.operator != 'between'
                    "
                    placeholder="Pick date range"
                    v-model="filter.selected_date_picker"
                    size="mini"
                    @change="
                      (changedValue) => setDatePickerDate(changedValue, filter)
                    "
                  >
                    <el-option label="Today" value="today"> Today </el-option>
                    <el-option label="Yesterday" value="yesterday">
                      Yesterday
                    </el-option>
                  </el-select>
                </td>
                <td>
                  <el-date-picker
                    v-model="filter.value"
                    size="mini"
                    v-if="
                      filter.data_source == 'VALUE' &&
                      filter.data_type == 'DATE_TIME' &&
                      filter.operator != 'real_time' &&
                      filter.operator != 'between'
                    "
                    :format="
                      getDefaultDateFormat &&
                      getDefaultDateFormat.includes('Do') == false
                        ? getDefaultDateFormat
                            .replace('YYYY', 'yyyy')
                            .replace('DD', 'dd')
                        : 'MM-dd-yyyy'
                    "
                  ></el-date-picker>
                </td>
                <td
                  v-if="
                    ((filter.data_source == 'GLOBAL_VARIABLE' &&
                      filter.global_variable_id) ||
                      filter.data_source == 'self_field') &&
                    checkIsSelect(filter)
                  "
                >
                  <el-select
                    size="mini"
                    v-model="filter.value"
                    placeholder="Select options"
                  >
                    <el-option
                      v-for="(op, index) in getCurrentGlobalVaribleOptions(
                        filter
                      )"
                      :key="index"
                      :multiple="
                        filter.operator == 'in' || filter.operator == 'nin'
                          ? true
                          : false
                      "
                      :value="op"
                      :label="op"
                    >
                    </el-option>
                  </el-select>
                </td>
              </template>
              <td>
                <el-button
                  type="text"
                  size="mini"
                  @click="removeFilterFromList(index)"
                >
                  Remove
                </el-button>
              </td>
              <td
                v-if="
                  filter.field !== 'UNIQUE' &&
                  filter.field !== 'RESOURCE_MANAGEMENT' &&
                  field.input_type != 'ENTITY_TABLE'
                "
              >
                <el-checkbox v-model="filter.preferred_filter">
                  Preferred data
                </el-checkbox>
              </td>
            </tr>
          </td>
        </tr>
      </table>
      <p id="lastFilter" ref="lastFilter"></p>
      <!-- </el-scrollbar> -->
      <!-- </el-collapse-item>
        </el-collapse> -->
      <el-dialog
        :visible.sync="updateResourceModal"
        :title="'Update ' + (field && field.label ? field.label : '')"
        :before-close="resetUpdateResourceModal"
      >
        <div
          class="mb-1"
          v-if="selectedFilter && selectedFilter.resource_count !== 'ALL_DATA'"
        >
          Resource count update field:
          <el-select
            v-model="updateResourceData.resource_count_field"
            placeholder="Field"
            size="mini"
            :id="`resource_update`"
            :ref="`resource_update`"
          >
            <el-option
              v-for="(field, index) of selectedEntityFields.filter(
                (e) => e.input_type == 'NUMBER'
              )"
              :key="index + 'update_field'"
              :value="field.key"
              :label="`${field.template_name} - ${field.label}`"
              :disabled="checkFieldExists(field)"
            >
              {{ field.template_name }} - {{ field.label }}
            </el-option>
          </el-select>
          <el-checkbox
            class="ml-1"
            v-model="updateResourceData.resource_count_subtract"
          >
            Subtract value
          </el-checkbox>
        </div>
        <div>
          <div>
            When resource count fully selected:
            <el-button
              @click="addElement"
              type="text"
              icon="el-icon-plus"
              class="float-right"
            >
              ADD
            </el-button>
          </div>
          <el-row :gutter="30">
            <el-col :span="8"><b> Update field </b></el-col>
            <el-col :span="8"> <b>Update value</b> </el-col>
            <el-col :span="4"> <b>Actions</b> </el-col>
          </el-row>
          <el-row
            v-for="(row, index) in updateResourceData.resource_state_change"
            :key="index + '_row_data'"
            :gutter="30"
            class="mt-1"
          >
            <el-col :span="8">
              <el-select
                v-model="row.field"
                placeholder="Field"
                size="mini"
                :id="`filters_update_${index}`"
                :ref="`filters_update_${index}`"
              >
                <el-option
                  v-for="(field, index) of selectedEntityFields.filter(
                    (e) =>
                      e.input_type != 'ENTITY_VARIABLE' &&
                      e.input_type != 'FORMULA' &&
                      e.input_type != 'RELATION' &&
                      e.field != 'self#related_to/parent' &&
                      e.field != 'self#related_to/self'
                  )"
                  :key="index"
                  :value="field.key"
                  :label="`${field.template_name} - ${field.label} - ${field.data_type} `"
                  :disabled="checkFieldExists(field)"
                >
                  {{ field.template_name }} - {{ field.label }}
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="8" v-if="row.field">
              <AnyFieldsExecute
                :fieldsData="selectedEntityFields"
                :field="selectedEntityFields.find((e) => e.key == row.field)"
                :form="updateResourceData.resource_state_data"
              ></AnyFieldsExecute>
            </el-col>
            <el-col :span="4">
              <el-link
                type="danger"
                class="delete-icon"
                icon="el-icon-delete"
                @click="removeElement(index)"
              ></el-link>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="resetUpdateResourceModal">Cancel</el-button>
          <el-button type="primary" @click="saveUpdateResource">Save</el-button>
        </span>
      </el-dialog>
    </el-col>
    <!-- </el-row> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "../../../main";
export default {
  components: {
    AnyFieldsExecute: () =>
      import("../formComponentsExecute/AnyFieldsExecute.vue"),
  },
  computed: {
    ...mapGetters("companyEntities", [
      "getAllCompanyEntitiesData",
      "getEntityFields",
    ]),
    ...mapGetters("globalVariables", [
      "getAllGlobalVariables",
      "getGlobalVariableDeleteStatus",
      "getGlobalVariableAddStatus",
      "getGlobalVariableDeleteError",
      "getUserDeleteError",
      "getAllGlobalVariableTypes",
    ]),
    ...mapGetters("companyTemplates", ["getSingleCompanyTemplate"]),
    ...mapGetters("entitiesData", ["getEntityPrimaryFieldsDataWithHash"]),
    ...mapGetters("entityRelationships", [
      "getAllExistedEntityRelationships",
      "getEntityNestedRelationships",
    ]),
    ...mapGetters("entities", [
      "getEntityDataByEntityId",
      "getEntityById",
      "getEntityRecordsForTable",
    ]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),
    getTemplateGroupByFields() {
      let unwantedFields = [
        "DATA_TABLE",
        "IMAGE",
        "HORIZONTAL_LINE",
        "HEADING",
        "VIDEO",
        "FILE",
        "PARAGRAPH",
        "SINGLE_LINE_CONTENT",
      ];
      if (this.fieldsData && this.fieldsData.length) {
        return this.fieldsData.filter(
          (field) =>
            field.key !== this.field.key &&
            field.relationship_key != this.field.key &&
            unwantedFields.indexOf(field.inputType) === -1
        );
      }
      return [];
    },
    entilistAsOptions() {
      return (filter) => {
        let field = this.selectedEntityFields.find(
          (e) => e.key == filter.field
        );
        if (
          field &&
          field.entity_id &&
          this.entityDataInfo &&
          this.entityDataInfo.length
        ) {
          let selectedEntity = this.entityDataInfo.find(
            (e) => e.entity_id === field.entity_id
          );
          if (selectedEntity && selectedEntity.data) {
            return selectedEntity.data;
          }
        } else {
          return [];
        }
      };
    },
    checkOtherAutofills() {
      if (this.field.data_table_key && this.field.data_table_field_index > -1) {
        let parentField = this.fieldsData.find(
          (e) => e.key == this.field.data_table_key
        );
        if (
          parentField &&
          parentField.data_table_columns &&
          parentField.data_table_columns.length
        ) {
          return parentField.data_table_columns.find(
            (el) =>
              el.key !== this.field.key &&
              el.inputType == "ENTITY" &&
              el.data_table_auto_fill
          )
            ? false
            : true;
        }
        return true;
      }
      return false;
    },
    checkPreferredFilter() {
      return this.field && this.field.filters
        ? this.field.filters.find((e) => e.preferred_filter)
          ? true
          : false
        : false;
    },
  },
  props: ["field", "fieldsData", "documentUsers", "fromDocument"],
  data() {
    return {
      weekdays_options: [
        { name: "Monday", value: 2, key: "MON" },
        { name: "Tuesday", value: 3, key: "TUE" },
        { name: "Wednesday", value: 4, key: "WED" },
        { name: "Thursday", value: 5, key: "THU" },
        { name: "Friday", value: 6, key: "FRI" },
        { name: "Saturday", value: 7, key: "SAT" },
        { name: "Sunday", value: 1, key: "SUN" },
      ],
      query_type: "OR",
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1",
        },
        {
          title: "Folder 2",
          _id: "2",
        },
        {
          title: "Folder 3",
          _id: "3",
        },
      ],
      newFilterData: {
        field: "",
        operator: "",
        value: "",
        value_type: "",
        value_source: "",
        data_type: "",
        query_type: "AND",
        value_field: "",
        validations: {
          data_type: "",
        },
        nested_related_id: "",
      },
      selectedEntityFields: [],
      selectedEntityFieldData: {},
      selectedEntity: {},
      entities: [],
      parent_key: "",
      actionFieldData: [],
      entitiesData: [],
      globalVariablesList: [],
      globalVariablesListForInputType: {},
      loading: false,
      filters: [],
      currentFilterIndex: -1,
      operatorsMap: {
        NUMBER: [
          {
            title: "Equals (=)",
            value: "=",
          },
          {
            title: "Greater Than (>)",
            value: ">",
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">=",
          },
          {
            title: "Less Than (<)",
            value: "<",
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<=",
          },
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        CURRENCY: [
          {
            title: "Equals (=)",
            value: "=",
          },
          {
            title: "Greater Than (>)",
            value: ">",
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">=",
          },
          {
            title: "Less Than (<)",
            value: "<",
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<=",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Range",
            value: "between",
          },
        ],
        STRING: [
          {
            title: "Equals (Case-sensitive)",
            value: "=case-sensitive",
          },
          {
            title: "Equals (Case-insensitive)",
            value: "=case-insensitive",
          },
          {
            title: "Text Includes",
            value: "=text-includes",
          },
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        EMAIL: [
          {
            title: "Equals (Case-sensitive)",
            value: "=case-sensitive",
          },
          {
            title: "Equals (Case-insensitive)",
            value: "=case-insensitive",
          },
          {
            title: "Text Includes",
            value: "=text-includes",
          },
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
        ],

        OBJECT_ID: [
          // {
          //   title: "Equals (=)",
          //   value: "=",
          // },
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
        ],
        DATE: [
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Equals (=)",
            value: "=",
          },
          {
            title: "Greater Than (>)",
            value: ">",
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">=",
          },
          {
            title: "Less Than (<)",
            value: "<",
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<=",
          },
          {
            title: "Between",
            value: "between",
          },
          {
            title: "Not Between",
            value: "notbetween",
          },
        ],
        RELATION: [
          {
            title: "Equals (=)",
            value: "=",
          },
        ],
        ARRAY: [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        DATE_TIME: [
          {
            title: "Date includes",
            value: "dateinclude",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        WEEKDAYS: [
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
        ],
        TIME: [
          {
            title: "Equals (=)",
            value: "=text-includes",
          },
          {
            title: "Greater Than (>)",
            value: ">",
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">=",
          },
          {
            title: "Less Than (<)",
            value: "<",
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<=",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
          // {
          //   title: "Between",
          //   value: "between"
          // }
        ],
        TIME_RANGE: [
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Time includes",
            value: "timeinclude",
          },
        ],
        YES_OR_NO: [
          {
            title: "Equals",
            value: "=case-sensitive",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        BOOLEAN: [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
      },

      operatorValueTypeMap: [
        {
          value_type: "VALUE",
          operators: [
            "=",
            "<=",
            "<",
            ">",
            ">=",
            "=case-insensitive",
            "=case-sensitive",
            "=text-includes",
            "!=",
            "dateinclude",
            "timeinclude",
            "in",
            "nin",
            "between",
            "notbetween",
          ],
          data_types: [
            "NUMBER",
            "STRING",
            "DATE",
            "TIME",
            "DATE_TIME",
            "TIME_RANGE",
            "YES_OR_NO",
            "CURRENCY",
            "LIST",
            "WEEKDAYS",
            "BOOLEAN",
          ],
        },
        {
          value_type: "GLOBAL_VARIABLE",
          operators: [
            "=",
            "<=",
            ">=",
            "<",
            ">",
            "=case-insensitive",
            "=text-includes",
            "=case-sensitive",
            "!=",
          ],
          data_types: ["NUMBER", "STRING"],
        },
        {
          value_type: "NEW_LIST",
          operators: ["in", "nin"],
          data_types: ["NUMBER"],
        },

        {
          value_type: "ENTITY_DATA_LIST",
          operators: ["in", "nin"],
          data_types: ["OBJECT_ID", "RELATION"],
        },
        {
          value_type: "TEMPLATE",
          operators: ["in", "nin"],
          data_types: ["OBJECT_ID", "RELATION"],
        },
        {
          value_type: "REAL_TIME_PICKER",
          operators: ["dateinclude"],
          data_types: ["DATE_TIME"],
        },
      ],

      allEntityIds: [],

      details: [
        {
          id: 1,
          label: "GROUP",
          type: "GROUP",
          group_value: "",
          children: [],
        },
      ],
      activeTab: ["1"],
      templateEntityFields: [],
      entityRationships: [],
      loginUserFields: [
        {
          value: "email",
          label: "Email",
        },
        {
          value: "first_name",
          label: "First Name",
        },
        {
          value: "last_name",
          label: "Last Name",
        },
      ],
      nestedRelationshipsData: [],
      entityDataInfo: [],
      updateResourceModal: false,
      updateResourceData: {
        resource_count_field: "",
        resource_state_change: [],
        resource_state_data: {},
        resource_count_subtract: false,
      },
      selectedFilter: null,
      selectedFiterIndex: -1,
    };
  },
  async mounted() {
    this.loading = true;
    if (this.getAllCompanyEntitiesData && this.getAllCompanyEntitiesData.data) {
      this.entitiesData = [
        ...this.entitiesData,
        ...this.getAllCompanyEntitiesData.data,
      ];
    }

    if (!this.field.relation_filters) {
      this.$set(this.field, "relation_filters", {
        status: false,
        related_entity: "",
        related_entity_field: "",
      });
    }

    if (!this.field.filters) {
      this.$set(this.field, "filters", []);
    } else if (
      this.field.filters &&
      this.field.filters[0] &&
      this.field.filters[0].query_type
    ) {
      this.query_type = this.field.filters[0].query_type;
    }

    if (this.field && this.field.entity_id) {
      await this.setEntityFields(this.field.entity_id);
    }

    await this.addSelfTemplateFields();
    if (this.field.filters && this.field.filters.length) {
      this.field.filters = this.field.filters.map((e) => {
        if (
          e.unique_field &&
          e.unique_field.includes("#") &&
          this.field?.template_id
        ) {
          e.unique_field =
            this.field.template_id + "#" + e.unique_field.split("#")[1];
        }
        return e;
      });
      this.field.filters.forEach((element) => {
        if (
          element &&
          (element.data_type == "RELATION" ||
            element.data_type == "OBJECT_ID") &&
          element.relation_entity_id
        ) {
          this.fetchEntityPrimaryFieldsData(element.relation_entity_id);
        }
      });
    }
    // await this.checkExistedFilters();

    this.loading = false;
    await this.fetchGlobalVariables();
  },
  methods: {
    setFilterInputType(value, filter) {
      if (value && value !== "ALL_DATA") {
        let field = this.getTemplateGroupByFields.find(
          (option) => option.template_id + "#" + option.key == value
        );
        filter.input_type = field && field.inputType ? field.inputType : "";
      } else {
        filter.input_type = "";
      }
    },
    removeElement(i) {
      this.updateResourceData.resource_state_change.splice(i, 1);
    },
    addElement() {
      this.updateResourceData.resource_state_change.push({});
    },
    checkFieldExists(field) {
      if (this.updateResourceData?.resource_state_change && field.key) {
        let exist = this.updateResourceData.resource_state_change.find(
          (e) => e.field == field.key
        );
        return exist ? true : false;
      }
      return false;
    },
    saveUpdateResource() {
      this.field.filters[this.selectedFiterIndex]["update_resource_data"] = {
        ...this.updateResourceData,
      };
      this.resetUpdateResourceModal();
    },
    resetUpdateResourceModal() {
      this.selectedFilter = null;
      this.selectedFiterIndex = -1;
      this.updateResourceModal = false;
    },
    openUpdateResourceModal(filter, index) {
      this.updateResourceModal = true;
      this.selectedFilter = { ...filter };
      if (this.selectedFilter?.update_resource_data) {
        this.updateResourceData = {
          ...this.selectedFilter.update_resource_data,
        };
      }
      this.selectedFiterIndex = index;
    },
    getLabel(item, i) {
      let selectedEntity = (this.entityDataInfo || []).find(
        (e) => e.entity_id == item.entity_id
      );
      if (selectedEntity && selectedEntity.entity) {
        selectedEntity = selectedEntity.entity;
      }
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (item.entity_prime_data && item.entity_prime_data[key]) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
              primaryField.template_id &&
              item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                  ? item.entityData[primaryField.template_id][key]
                  : "data" + i
                : "data" + i;
          }
        }
      }
      return name;
    },
    getCurrentTemplateCountFields(types) {
      let newFields = JSON.parse(JSON.stringify(this.fieldsData));
      return (newFields || [])
        .filter(
          (e) =>
            types.indexOf(e.inputType) != -1 ||
            (e.inputType == "FORMULA" && types.indexOf(e.result_type) != -1) ||
            (e.inputType == "AGGREGATE_FUNCTION" &&
              (types.indexOf("NUMBER") != -1 ||
                types.indexOf("CURRENCY") != -1))
        )
        .map((e) => {
          if (e?.key && e.key.includes("#")) {
            e.key = e.key.split("#")[1];
          }
          return e;
        });
    },
    getSelectedEntityCountFields(types) {
      let newFields = JSON.parse(JSON.stringify(this.selectedEntityFields));
      return (newFields || [])
        .filter(
          (e) =>
            types.indexOf(e.inputType) != -1 ||
            (e.inputType == "FORMULA" && types.indexOf(e.result_type) != -1) ||
            (e.inputType == "AGGREGATE_FUNCTION" &&
              (types.indexOf("NUMBER") != -1 ||
                types.indexOf("CURRENCY") != -1))
        )
        .map((e) => {
          if (e?.key && e.key.includes("#")) {
            e.key = e.key.split("#")[1];
          }
          return e;
        });
    },
    getMatchedTypeEntityFields(filter, type) {
      if (filter?.field) {
        let newFields = JSON.parse(JSON.stringify(this.selectedEntityFields));
        return newFields
          .map((e) => {
            if (e?.key && e.key.includes("#")) {
              e.key = e.key.split("#")[1];
            }
            return e;
          })
          .filter((field) => !type || field.inputType == type);
      }
      return [];
    },
    getMatchedTypeFields(filter, type) {
      if (filter?.field) {
        let newFields = JSON.parse(JSON.stringify(this.fieldsData));
        return newFields
          .map((e) => {
            if (e?.key && e.key.includes("#")) {
              e.key = e.key.split("#")[1];
            }
            return e;
          })
          .filter((field) => !type || field.inputType == type);
      }
      return [];
    },
    checkCurrentFieldValue(field, filter) {
      filter.value = field;
    },
    updateQueryType() {
      this.field.filters.map((e) => (e.query_type = this.query_type));
    },
    checkUserEntites(filter) {
      let field = this.selectedEntityFields.find(
        (field) => field.key == filter.field
      );
      if (
        field &&
        field.input_type == "RELATION" &&
        field.data_source === "user"
      ) {
        return true;
      }
      return false;
    },
    checkSelfTemplateFields(filter) {
      let field = this.selectedEntityFields.find(
        (field) => field.key == filter.field
      );
      if (
        field &&
        field.input_type == "RELATION" &&
        field.data_source != "user"
      ) {
        return true;
      }
      // if (field && field.input_type) {
      //   return (this.fieldsData || []).filter(
      //     (e) => e.input_type == field.input_type
      //   ).length
      //     ? true
      //     : false;
      // }
      return false;
    },
    //   getSelfTemplateFieldsByType(filter) {
    //   let field = this.selectedEntityFields.find(
    //     (field) => field.key === filter.field
    //   );

    //   if (!field) {
    //     return [];
    //   }
    //   if (field.inputType === "DATE" || (field.inputType === "FORMULA" && field.result_type === "DATE")) {
    //     return (this.fieldsData || []).filter(
    //       (e) => (e.data_type === "DATE" ||
    //               (e.input_type === 'FORMULA' && e.result_type === 'DATE')) &&
    //               e.key !== field.key
    //     );
    //   }

    //   return (this.fieldsData || []).filter(
    //     (e) =>
    //       ((field.inputType !== "ENTITY" && e.inputType === field.inputType) ||
    //         (field.inputType === "ENTITY" &&
    //           field.entity_id === e.entity_id &&
    //           e.inputType === "ENTITY") ||
    //         (["MULTI_SELECT", "SELECT"].includes(field.inputType) &&
    //           ["MULTI_SELECT", "SELECT"].includes(e.inputType)) ||
    //         (["TIME", "TIME_RANGE"].includes(field.inputType) &&
    //           ["TIME", "TIME_RANGE"].includes(e.inputType))) &&
    //       e.key !== field.key
    //   );
    // },
    getSelfTemplateFieldsByType(filter) {
      let field = this.selectedEntityFields.find(
        (field) => field.key === filter.field
      );

      if (!field) {
        return [];
      }

      let dateSpecificFields = [];
      if (
        field.inputType === "DATE" ||
        (field.inputType === "FORMULA" && field.result_type === "DATE")
      ) {
        dateSpecificFields = (this.fieldsData || []).filter(
          (e) =>
            (e.data_type === "DATE" ||
              (e.input_type === "FORMULA" && e.result_type === "DATE")) &&
            e.key !== field.key
        );
      }

      let generalFields = (this.fieldsData || []).filter(
        (e) =>
          ((field.inputType !== "ENTITY" && e.inputType === field.inputType) ||
            (field.inputType === "ENTITY" &&
              field.entity_id === e.entity_id &&
              e.inputType === "ENTITY") ||
            (["MULTI_SELECT", "SELECT"].includes(field.inputType) &&
              ["MULTI_SELECT", "SELECT"].includes(e.inputType)) ||
            (["TIME", "TIME_RANGE"].includes(field.inputType) &&
              ["TIME", "TIME_RANGE"].includes(e.inputType))) &&
          e.key !== field.key
      );

      return [...dateSpecificFields, ...generalFields];
    },

    getSelfTemplateFields(filter) {
      if (filter.field) {
        let field = this.selectedEntityFields.find(
          (field) => field.key == filter.field
        );
        if (field && field.input_type) {
          let entityFields =
            (this.fieldsData || []).filter(
              (e) =>
                e.input_type == field.input_type ||
                (field.input_type == "RELATION" &&
                  (e.inputType == "ENTITY" || e.type == "ENTITY") &&
                  e.entity_id != this.field.entity_id)
            ) || [];
          entityFields = entityFields.filter((e) => {
            let related = (this.existedRelationships || []).find(
              (r) =>
                (r.parent_entity_id == e.entity_id ||
                  r.child_entity_id == e.entity_id) &&
                filter?.field &&
                filter.field.includes("self#")
            );
            let nestedRelated = this.nestedRelationshipsData.find(
              (el) =>
                el.child_entity_id == e.entity_id &&
                filter?.field &&
                filter.field.includes("nested#")
            );
            let selected = this.field.filters.find(
              (flt) =>
                flt.relation_entity_id == e.entity_id &&
                flt.field !== filter.field
            );
            return ((related && !nestedRelated) ||
              (nestedRelated && field?.nested_related_id && !related)) &&
              !selected
              ? true
              : false;
          });
          return entityFields;
        }
      } else {
        return [];
      }
    },
    fieldDisabled(field) {
      if (
        this.field.data_table_field_auto_fill &&
        this.field.selected_auto_fill_entity == field.entity_id
      ) {
        return true;
      }
      return false;
    },
    checkIsTemplateFieldSelect(filter) {
      let field = this.selectedEntityFields.find(
        (field) => field.key == filter.field
      );
      console.log("111", field);
      return false;
    },
    checkIsSelect(filter) {
      let field = this.selectedEntityFields.find(
        (field) => field.key == filter.field
      );
      if (
        field &&
        (field.input_type == "SELECT" ||
          field.input_type == "MULTI_SELECT" ||
          field.input_type == "CHECKBOX_GROUP" ||
          field.input_type == "RADIO_BUTTON_GROUP")
      ) {
        return true;
      }
      return false;
    },

    getReadbleValues(primaryFieldsData) {
      return Object.values(primaryFieldsData).join(" ");
    },

    allowedDataSources(filter) {
      let operatorValues = this.operatorValueTypeMap.filter((valueType) => {
        return (
          valueType.operators.indexOf(filter.operator) > -1 &&
          valueType.data_types.indexOf(filter.data_type) > -1
        );
      });
      return operatorValues.map((e) => e.value_type);
    },

    async addSelfTemplateFields() {
      if (this.fieldsData && this.fieldsData.length) {
        this.fieldsData.forEach((element) => {
          if (
            element.input_type == "GLOBAL_VARIABLE" ||
            element.input_type == "ENTITY_VARIABLE"
          ) {
            element["is_template_variable"] = true;
            this.entities.push(element);
          }
        });
      }
    },

    async getGlobalVariables(value, filter) {
      if (value) {
        // we have to fetch all globlal variables
        let fieldKey = filter.field;

        let field = this.selectedEntityFields.find(
          (field) => field.key == fieldKey
        );

        if (field && field.input_type) {
          await this.fetchGlobalVariables();
        }
      }
    },

    async fetchGlobalVariables() {
      try {
        const query = { get_all: true };

        await this.$store.dispatch(
          "globalVariables/fetchGlobalVariables",
          query
        );

        this.globalVariablesList = [...this.getAllGlobalVariables.data];
      } catch (err) {
        console.log("fetchGlobalVariables", err);
      }
    },

    checkFilterExistedOrNot(field) {
      if (
        this.field.data_table_field_auto_fill &&
        field.key == "self#related_to/parent" &&
        field.key == "self#related_to/self"
      ) {
        return true;
      }
      return this.field.filters.find((x) => x.field == field.key)
        ? true
        : false;
    },

    getEntityVariables() {
      let fields = [];
      this.fieldsData.forEach((element) => {
        if (
          element.input_type == "GLOBAL_VARIABLE" ||
          element.input_type == "ENTITY_VARIABLE"
        ) {
          fields.push(element);
        }
      });
      return fields;
    },
    getEntities() {
      let fields = [];
      this.fieldsData.forEach((element) => {
        if (
          element.input_type == "ENTITY" &&
          element.entity_id != this.field.entity_id
        ) {
          fields.push(element);
        }
      });
      return fields;
    },

    async setEntityFields(entityId) {
      try {
        this.loading = true;
        await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
          entity_id: entityId,
        });

        this.selectedEntityFields = [];
        if (this.getEntityById && this.getEntityById.templates) {
          this.getEntityById.templates.forEach((e) => {
            if (e && e.templateInfo && !e.is_repeatable) {
              this.selectedEntityFields = [
                ...this.selectedEntityFields,
                ...this.getTemplateFields(e.templateInfo),
              ];
            }
          });
          await this.checkRationshipsData(entityId);
        }
        this.selectedEntityFields = [
          ...this.selectedEntityFields,
          ...[
            {
              input_type: "RELATION",
              entity_id: this.field.entity_id,
              key: "self#related_to/parent",
              label: "Related To Parent",
              section_name: "Self",
              template_name: "Self",
              data_type: "RELATION",
              validations: {
                data_type: "RELATION",
              },
              nested_related_id: "",
            },
            {
              input_type: "RELATION",
              entity_id: this.field.entity_id,
              key: "self#related_to/self",
              label: "Related To Itself",
              section_name: "Self",
              template_name: "Self",
              data_type: "RELATION",
              validations: {
                data_type: "RELATION",
              },
              nested_related_id: "",
            },
          ],
        ];
        this.loading = false;
      } catch (err) {
        console.log("setEntityFields", err);
      }
    },
    async checkUserEntityRelationships(entityId) {
      let filteredUsers = this.documentUsers
        .filter((e) => e.contact_type && e.contact_type != entityId)
        .map((e) => {
          if (e.contact_type && e.contact_type._id) {
            e.contact_type = e.contact_type._id;
          }
          return e;
        });
      let userRelationshipFilter = filteredUsers.map((e) => {
        return {
          parent_entity_id: entityId,
          child_entity_id: e.contact_type,
        };
      });
      await this.$store.dispatch(
        "entityRelationships/fetchAllExistedRelationships",
        {
          relationships_data: userRelationshipFilter,
        }
      );
      let relationData = [...this.getAllExistedEntityRelationships];
      filteredUsers.forEach((user) => {
        let hasRelation = relationData.find(
          (r) =>
            r.parent_entity_id == user.contact_type ||
            r.child_entity_id == user.contact_type
        );
        if (hasRelation) {
          this.selectedEntityFields.push({
            input_type: "RELATION",
            entity_id: entityId,
            key: `user#related_to/${user.value}`,
            label: `Related To # ${user.name}`,
            section_name: "User",
            template_name: "User",
            data_source: "user",
            data_type: "RELATION",
            validations: {
              data_type: "RELATION",
            },
          });
        }
      });
    },
    async checkRationshipsData(entityId) {
      this.templateEntityFields = [];
      this.templateEntityFields = this.fieldsData.filter(
        (e) =>
          (e.inputType == "ENTITY" || e.type == "ENTITY") &&
          !e.show_count &&
          e.entity_id &&
          e.entity_id != entityId
      );
      if (this.fromDocument) {
        await this.checkUserEntityRelationships(entityId);
      }
      if (this.templateEntityFields.length) {
        let relationshipFilter = this.templateEntityFields.map((e) => {
          return {
            parent_entity_id: entityId,
            child_entity_id: e.entity_id,
          };
        });

        await Promise.all([
          await this.$store.dispatch(
            "entityRelationships/fetchAllExistedRelationships",
            {
              relationships_data: relationshipFilter,
            }
          ),
          await this.$store.dispatch(
            "entityRelationships/fetchEntityNestedRelationships",
            { entity_id: entityId }
          ),
        ]);
        this.nestedRelationshipsData = [
          ...(this.getEntityNestedRelationships || []),
        ];
        this.existedRelationships = [...this.getAllExistedEntityRelationships];
        let count = 0;
        this.nestedRelationshipsData = this.nestedRelationshipsData.filter(
          (nRelation) => {
            let directRelationship = (this.existedRelationships || []).find(
              (relation) => {
                if (
                  nRelation.child_entity_id == relation.child_entity_id ||
                  nRelation.child_entity_id == relation.parent_entity_id
                ) {
                  return relation;
                }
              }
            );
            if (!directRelationship) {
              return true;
            }
          }
        );
        this.templateEntityFields.forEach((field) => {
          let realted = (this.existedRelationships || []).find(
            (r) =>
              r.parent_entity_id == field.entity_id ||
              r.child_entity_id == field.entity_id
          );
          let nestedRelated = this.nestedRelationshipsData.find(
            (el) => el.child_entity_id == field.entity_id
          );
          let parentField = this.fieldsData.find(
            (e) => e.key == field.relationship_key
          );
          if (
            realted ||
            (nestedRelated &&
              !(
                parentField &&
                field.input_type == "ENTITY_VARIABLE" &&
                ((realted &&
                  realted.parent_entity_id != parentField.entity_id &&
                  realted.child_entity_id != parentField.entity_id) ||
                  (nestedRelated &&
                    nestedRelated.parent_entity_id != parentField.entity_id &&
                    nestedRelated.child_entity_id != parentField.entity_id))
              ))
          ) {
            this.selectedEntityFields.push({
              input_type: "RELATION",
              entity_id: entityId,
              key: realted
                ? "self#related_to/" + (count + 1)
                : "nested#related_to/" + (count + 1),
              label: realted
                ? `Related To #` + (count + 1)
                : `Nested related To #` + (count + 1),
              section_name: "Self",
              template_name: "Self",
              data_type: "RELATION",
              validations: {
                data_type: "RELATION",
              },
              nested_related_id: nestedRelated?.parent_entity_id
                ? nestedRelated?.parent_entity_id
                : "",
            });
            count++;
          }
        });
      } else {
        this.existedRelationships = [];
      }
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        e.key = data._id + "#" + e.key;
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
    setFieldData(fieldId) {
      let selectedEntityField = {};
      selectedEntityField = this.selectedEntityFields.find(
        (field) => field._id == fieldId
      );
      if (selectedEntityField == undefined) {
        this.actionFieldData.forEach((repeatableTemps) => {
          repeatableTemps.sections[0].fields.forEach((field) => {
            if (field._id == fieldId) {
              selectedEntityField = field;
            }
          });
        });
      }
      this.selectedEntityFieldData = selectedEntityField;
      this.field.selected_enity_field_data = this.selectedEntityFieldData;
      if (selectedEntityField && selectedEntityField.is_relational) {
        this.field.entity_id = selectedEntityField.relation_entity_id;
        this.field.relationship_entity_id = selectedEntityField.self_entity_id;
      }
      if (this.parent_key) {
        this.field.global_variable_entity_parent = this.parent_key;
      }
    },
    getCurrentGlobalVaribleOptions(filter) {
      if (filter.data_source == "self_field") {
        let selectedField = this.fieldsData.find(
          (e) => e.key == filter.value_field
        );
        if (selectedField && selectedField.options) {
          return selectedField.options;
        }
      } else {
        let globalVariable = this.globalVariablesList.find(
          (e) => e._id == filter.global_variable_id
        );
        if (globalVariable && globalVariable.options) {
          return globalVariable.options;
        }
      }
      return [];
    },
    selectedGlobalVariablesList(filter) {
      let field = this.selectedEntityFields.find((e) => e.key == filter.field);
      return this.globalVariablesList.filter(
        (x) => x.input_type == field.input_type
      );
    },
    hasAllowedOperator(filterData, type) {
      let operator = filterData.operator;
      let operatorVaule = this.operatorValueTypeMap.find(
        (e) =>
          e.value_type == type &&
          e.data_types.indexOf(filterData.data_type) > -1
      );
      return operatorVaule && operatorVaule.operators.includes(operator);
    },

    getFieldName(filterData) {
      let field = this.selectedEntityFields.find(
        (field) => field.key == filterData.field
      );

      if (field) {
        return field.label;
      }
    },

    getOperatorName(filterData) {
      return filterData.operator;
    },

    getValueNames(filterData) {
      if (filterData.data_source == "ENTITY_DATA_LIST") {
        let field = this.selectedEntityFields.find(
          (field) => field.key == filterData.field
        );

        if (field) {
          let results =
            this.getEntityPrimaryFieldsDataWithHash[field.entity_id];

          return filterData.value.map((e) => {
            let data = results.find((r) => r.entity_data_id == e);
            if (data) {
              return this.getReadbleValues(data.primary_fields_data);
            }
            return "";
          });
        }
      } else if (filterData.data_source == "GLOBAL_VARIABLE") {
        // We have to fetch globlal variables
        let globalVariable = this.globalVariablesList.find(
          (x) => x._id == filterData.global_variable_id
        );

        if (globalVariable) {
          return globalVariable.label;
        }
        return "Global Variable";
      } else {
        if (
          filterData.data_type == "NUMBER" &&
          filterData.data_source == "NEW_LIST"
        ) {
          return filterData.value;
        }
        return filterData.value;
      }
    },
    checkData() {
      if (this.field.global_variable_entity_type) {
        this.setEntityFields(this.field.relationship_key, true);
        if (
          this.field.input_type == "GLOBAL_VARIABLE" &&
          this.field.global_variable_entity_field
        ) {
          this.setFieldData(this.field.global_variable_entity_field);
        }

        if (
          this.field.input_type == "ENTITY_VARIABLE" &&
          this.field.entity_id
        ) {
          this.setFieldData(this.field.global_variable_entity_field);
        }
      }
      this.entitiesData.map((entity) => {
        entity.sections.map((section) => {
          if (section.fields && section.fields.length) {
            section.fields.map((element) => {
              if (element.input_type == "ACTION_BUTTON") {
                let fieldIndex = this.actionFieldData.findIndex(
                  (e) => e._id == entity.action_button_repeating_template
                );
                entity["template_fields"] = this.actionFieldData[fieldIndex];
                entity["repeat_template_id"] =
                  entity.action_button_repeating_template;
                if (
                  this.actionFieldData[fieldIndex] &&
                  this.actionFieldData[fieldIndex].primary_fields
                ) {
                  let primaryFieldIndex = this.actionFieldData[
                    fieldIndex
                  ].primary_fields.findIndex(
                    (e) => e._id == this.data.global_variable_entity_field
                  );
                  entity["repeatable_primary_field"] =
                    primaryFieldIndex >= 0 ? true : false;
                } else {
                  entity["repeatable_primary_field"] = false;
                }
              }
            });
          }
        });
      });
    },
    onSelectParentData(key) {
      console.log("Selected Variable Key : ", key);
      console.log(
        "Selected Variable : ",
        this.field.global_variable_entity_parent
      );
    },
    async getFieldData(templateId) {
      try {
        await this.$store.dispatch(
          "companyTemplates/fetchSingleCompanyTemplate",
          templateId
        );
        if (this.getSingleCompanyTemplate) {
          this.actionFieldData.push(this.getSingleCompanyTemplate);
          return this.getSingleCompanyTemplate;
        }
      } catch (error) {
        console.log("getFieldData", error);
      }
    },
    setFieldType() {
      if (
        this.selectedEntityFieldData.input_type == "SELECT" ||
        this.selectedEntityFieldData.input_type == "MULTI_SELECT"
      ) {
        if (this.field.global_variable_entity_select_type == "single") {
          this.field.selected_enity_field_data.input_type = "SELECT";
          this.selectedEntityFieldData.input_type = "SELECT";
        }
        if (this.field.global_variable_entity_select_type == "multiple") {
          this.field.selected_enity_field_data.input_type = "MULTI_SELECT";
          this.selectedEntityFieldData.input_type = "MULTI_SELECT";
        }
      }
    },

    async checkSourceDataChange(source, filter) {
      if (source == "GLOBAL_VARIABLE") {
        await this.getGlobalVariables(source, filter);
        // we have to fetch global variables
      }
      //  else if (source == "ENTITY_DATA_LIST") {
      //   await this.getEntilistAsOptions(filter);
      // }
    },

    addNewFilter() {
      this.newFilterData = {
        field: "",
        operator: "",
        value: "",
      };
    },

    addFilter() {
      this.field.filters.push({
        ...this.newFilterData,
        ...{ query_type: this.query_type },
      });
      setTimeout(() => {
        bus.$emit("scroll-down", {});
      });
    },

    removeFilter() {
      this.newFilterData = {
        field: "",
        operator: "",
        value: "",
      };
    },

    removeFilterFromList(index) {
      if (index > -1) {
        this.field.filters.splice(index, 1);
      }
    },
    parseOptionsAsNumbers(data, filter) {
      if (filter.data_source == "NEW_LIST" && filter.data_type == "NUMBER") {
        filter.value = filter.value.map((e) => +e);
      }
    },
    clearNewFilter() {
      this.newFilterData = {
        field: "",
        operator: "",
        value: "",
      };
    },

    checkAndInsertNewFilter() {
      if (this.newFilterData.field == "self#entity_data") {
        // here we are clearing all filters and adding only one filter
        //:TODO we have to ask confirmation
        this.field.filters = [this.newFilterData];
      } else {
        if (this.field.filters.length == 1) {
          if (this.field.filters[0].field == "self#entity_data") {
            this.field.filters = [this.newFilterData];
          } else {
            this.field.filters.push(this.newFilterData);
          }
        } else {
          this.field.filters.push(this.newFilterData);
        }
      }
    },

    async checkCurrentFieldData(fieldKey, filter, index) {
      if (filter.field == "UNIQUE" || filter.field == "RESOURCE_MANAGEMENT") {
        return;
      }
      let field = this.selectedEntityFields.find(
        (field) => field.key == fieldKey
      );
      filter.data_type = this.getTypeFromInputType(
        field.inputType == "FORMULA" ? field.result_type : field.inputType
      );
      if (field.data_source == "user" || field.data_source == "user_entity") {
        filter.operator = "=";
        filter.data_source = "user_entity";
        filter.data_type = "RELATION";
        if (this.documentUsers && this.documentUsers.length && filter.field) {
          let selectedUser = this.documentUsers.find(
            (e) => e.value == filter.field.split("/")[1]
          );
          if (selectedUser.entity_data_id || selectedUser.entity_data_id._id) {
            filter.value =
              selectedUser.entity_data_id._id || selectedUser.entity_data_id;
          }
          if (selectedUser) {
            if (selectedUser.contact_type) {
              filter.relation_entity_id = selectedUser.contact_type._id
                ? selectedUser.contact_type._id
                : selectedUser.contact_type;
              if (this.field?.filters[index]) {
                let filterObj = { ...filter };
                filterObj["relation_entity_id"] = selectedUser.contact_type._id
                  ? selectedUser.contact_type._id
                  : selectedUser.contact_type;
                this.field.filters[index] = { ...filterObj };
              }
            }
          }
        }
      }
      if (field.input_type == "RELATION" || field.data_type == "OBJECT_ID") {
        filter.relation_entity_id = field.entity_id;
        //need to verify in all cases
        if (field.input_type !== "ENTITY") {
          filter.data_type = "RELATION";
        }
      }
      filter.input_type = field.input_type;
      await this.getEntilistAsOptions(filter);
    },
    getTypeFromInputType(inputType) {
      const obj = {
        SINGLE_LINE_TEXT: "STRING",
        MULTI_LINE_TEXT: "STRING",
        DATE: "DATE",
        DATE_TIME: "DATE",
        TIME: "TIME",
        NUMBER: "NUMBER",
        FILE: "FILE",
        SELECT: "ARRAY",
        YES_OR_NO: "YES_OR_NO",
        MULTI_SELECT: "ARRAY",
        CHECKBOX_GROUP: "ARRAY",
        RADIO_BUTTON_GROUP: "ARRAY",
        CHECKBOX: "BOOLEAN",
        RADIO: "BOOLEAN",
        HEADING: "STRING",
        SIGNATURE: "STRING",
        GLOBAL_VARIABLE: "OBJECT_ID",
        ENTITY: "OBJECT_ID",
        IMAGE: "STRING",
        PASSWORD: "STRING",
        MASKED: "STRING",
        EMAIL: "EMAIL",
        PHONE: "NUMBER",
        FAX: "NUMBER",
        CURRENCY: "NUMBER",
        PHONE_COUNTRY_CODE: "NUMBER",
        QUESTION: "STRING",
        DATE_TIME_RANGE: "DATE_TIME",
        DATE_RANGE: "DATE_RANGE",
        WEEKDAYS: "WEEKDAYS",
        LIST: "ARRAY",
        TIME_RANGE: "TIME_RANGE",
        RELATION: "RELATION",
        HYPER_LINK: "STRING",
      };
      return obj[inputType];
    },
    async getEntilistAsOptions(filter) {
      let fieldKey = filter.field;
      let field = this.selectedEntityFields.find(
        (field) => field.key == fieldKey
      );
      if (field.input_type == "ENTITY" || field.input_type == "RELATION") {
        await this.fetchEntityPrimaryFieldsData(field.entity_id);
      }
    },

    async fetchEntityPrimaryFieldsData(entity_id) {
      let params = {
        entity_id: entity_id,
        template_fields_data: [],
        filters: [],
      };
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      if (this.getEntityRecordsForTable && this.getEntityRecordsForTable.data) {
        this.entityDataInfo.push({
          entity: this.getEntityRecordsForTable.selectedEntity,
          data: this.getEntityRecordsForTable.data,
          entity_id: entity_id,
        });
      }
    },
    getCurrentFieldOptions(filter) {
      if (filter.field) {
        let field = this.selectedEntityFields.find(
          (field) => field.key == filter.field
        );
        if (
          field &&
          field.inputType == "SELECT" &&
          field.is_global_variable &&
          field.global_variable_id
        ) {
          let data = this.getAllGlobalVariables.data.find(
            (val) => val._id == field.global_variable_id
          );
          return data.options;
        } else if (field && field.options) {
          return field.options;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    getCurrentFieldOperators(filter) {
      if (filter.field) {
        if (filter.data_type) {
          return this.operatorsMap[filter.data_type];
        } else {
          let field = this.selectedEntityFields.find(
            (field) => field.key == filter.field
          );
          if (field) {
            return (
              this.operatorsMap[
                field.input_type == "FORMULA"
                  ? field.result_type
                  : field.data_type
              ] || []
            );
          } else {
            return [];
          }
        }
      } else {
        return [];
      }
    },

    onSelfFieldSelectedForFilter(filter, value) {
      let fieldKey = value;
      let allFields = this.getSelfTemplateFields(filter);
      let field = allFields.find((x) => x.key == fieldKey);
      filter.relation_entity_id = field.entity_id;
      //check is this nested relationship
      if (
        filter?.field &&
        filter.field.includes("#") &&
        filter.field.split("#")[0] == "nested"
      ) {
        let nestedRelation = this.selectedEntityFields.find(
          (field) => field.key == filter.field
        );
        if (nestedRelation?.nested_related_id) {
          filter.nested_related_id = nestedRelation.nested_related_id;
        }
      }
    },
  },

  watch: {
    "field.entity_id": {
      async handler() {
        this.clearNewFilter();
        if (!this.fromDocument) {
          this.selectedEntityFields = [];
          this.field.filters = [];
        }
        if (this.field.entity_id) {
          await this.setEntityFields(this.field.entity_id);
        }
      },
      // deep: true,
    },
  },
};
</script>

<style lang="scss">
th,
td {
  padding: 6px;
}

.el-tree-node__content {
  padding-left: 18px;
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
